<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Aset Bersertifikat </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputAsetwilayah">
            <CRow>

              <CCol sm="6">
                <CInput
                  v-model="kode_skpd"
                  label="Kode SKPD"
                  placeholder="Input kode SKPD"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nama_skpd"
                  label="Nama SKPD"
                  placeholder="Input nama SKPD"
                />
              </CCol>
              
              <CCol sm="6">
                <CInput
                  v-model="kabupaten_kota"
                  label="Kota/Kabupaten"
                  placeholder="Input kota/kabupaten"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="kecamatan"
                  label="Kecamatan"
                  placeholder="Input kecamatan"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="kelurahan"
                  label="Kelurahan"
                  placeholder="Input kelurahan"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="kode_lokasi"
                  label="Kode Lokasi"
                  placeholder="Input kode lokasi"
                />
              </CCol>

              <CCol sm="6">
                <CInput
                  v-model="nama_instansi"
                  label="Nama OPD"
                  placeholder="Input nama OPD"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="letak_lokasi"
                  label="Alamat"
                  placeholder="Input alamat"
                />
              </CCol>

              <CCol sm="6">
                <CInput
                  v-model="kode_barang"
                  label="Kode Barang"
                  placeholder="Input kode barang"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nama_barang"
                  label="Nama Barang"
                  placeholder="Input nama barang"
                />
              </CCol>

              <CCol sm="6">
                <CInput
                  v-model="no_hak"
                  label="Nomor HAK"
                  placeholder="Input nomor HAK"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="jenis_hak"
                  label="Tipe HAK"
                  placeholder="Input nomor HAK"
                />
              </CCol>

              <CCol sm="6">
                <CInput
                  type="date"
                  v-model="tgl_perolehan"
                  label="Tanggal Perolehan"
                  placeholder="Input tanggal perolehan"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nilai_perolehan"
                  label="Harga Perolehan"
                  placeholder="Input harga perolehan"
                />
              </CCol>

              <CCol sm="6">
                <CInput
                  v-model="luas_tertulis"
                  label="Luas (m2)"
                  placeholder="Input luas"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  type="date"
                  v-model="tgl_sertifikat"
                  label="Tanggal Sertifikat"
                  placeholder="Input tanggal sertifikat"
                />
              </CCol>

              <CCol sm="6">
                <div class="form-group">
                  <label> Sertifikat <a href='' target='_blank'>[Download]</a></label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange1($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Lokasi <a href='' target='_blank'>[Download]</a></label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder2" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder2}}</label>
                  </div>
                </div>  
              </CCol>
              
              <CCol sm="6">
                <div class="form-group">
                  <label>Alas HAK <a href='' target='_blank'>[Download]</a></label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange5($event)" id="inputGroupFile05"
                    :placeholder="placeholder5" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder5}}</label>
                  </div>
                </div>  
              </CCol> 
               
              <CCol sm="6">
                <div class="form-group">
                  <label>Buku Tanah <a href='' target='_blank'>[Download]</a></label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange6($event)" id="inputGroupFile06"
                    :placeholder="placeholder6" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder6}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Dikuasai </label>
                  <select v-model="dikuasai" placeholder="Pilihan" class="form-control">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="Dikuasai Masyarakat">Dikuasai Masyarakat</option>
                    <option value="Clean & Clear">Clean & Clear</option>
                  </select>
                </div>
              </CCol>

            </CRow>          
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/asetwilayah">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :show.sync="myerror"
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  data () {
    return {
      kode_skpd : "",
      nama_skpd : "",
      kabupaten_kota : "",
      kode_lokasi : "",
      nama_instansi : "",
      letak_lokasi : "",
      kode_barang : "",
      nama_barang : "",
      no_hak : "",
      jenis_hak : "",
      luas_tertulis : "",
      tgl_sertifikat : "",
      kecamatan : "",
      kelurahan : "",
      tgl_perolehan : "",
      nilai_perolehan : "",

      sertifikat : "",
      sertifikat_text : "",
      foto_lokasi : "",
      foto_lokasi_text : "",
      alas_hak : "",
      alas_hak_text : "",
      buku_tanah : "",
      buku_tanah_text : "",
      dikuasai : "",

      // file : "",
      // filename : "",
      myerror : false,
      placeholder : "Pilih File ",
      placeholder2 : "Pilih File",
      placeholder5 : "Pilih File",
      placeholder6 : "Pilih File",
      selected: [], // Must be an array reference!
      // ruangans: [],
      show: true,
    }
  },
  mounted(){
  this.loadData();
  },

  methods: {
    
    loadData: function(event){
      // axios.get("http://139.180.219.157/earsip/api/public/raw_ruangan/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
      //   .then((response) => {
      //         this.ruangans=response.data;
      //   });
    },
    
    onFileChange1(event) {
      var fileData = event.target.files[0];
      this.sertifikat_text = fileData.name;
      this.sertifikat = fileData;
      this.placeholder = fileData.name;
    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.foto_lokasi_text = fileData.name;
      this.foto_lokasi = fileData;
      this.placeholder2 = fileData.name;
    },
    onFileChange5(event) {
      var fileData = event.target.files[0];
      this.alas_hak_text = fileData.name;
      this.alas_hak = fileData;
      this.placeholder5 = fileData.name;
    },
    onFileChange6(event) {
      var fileData = event.target.files[0];
      this.buku_tanah_text = fileData.name;
      this.buku_tanah = fileData;
      this.placeholder6 = fileData.name;
    },
    inputAsetwilayah: function(event){
      // Simple POST request with a JSON body using axios
      // const keseluruhan = { 
      //                     nib:this.nib,
      //                     tipe_hak: this.tipe_hak, 
      //                     namadesa: this.namadesa, 
      //                     su: this.su, 
      //                     nomor_hak: this.nomor_hak, 
      //                     lemari: this.lemari, 
      //                     kolom: this.kolom, 
      //                     baris: this.baris,
      //                     ruangan: this.ruangan
      //                     };

      const formData = new FormData()
      // formData.append('buku_tanah', this.buku_tanah, this.buku_tanah.name)
      // formData.append('buku_tanah_text', this.buku_tanah.name)
      // formData.append('sertifikat', this.sertifikat, this.sertifikat.name)
      // formData.append('sertifikat_text', this.sertifikat.name)
      // formData.append('foto_lokasi', this.foto_lokasi, this.foto_lokasi.name)
      // formData.append('foto_lokasi_text', this.foto_lokasi.name)
      // formData.append('alas_hak', this.alas_hak, this.alas_hak.name)
      // formData.append('alas_hak_text', this.alas_hak.name)

      // formData.append('id_rka', this.$route.params.id)foto_lokasi_text
      formData.append('kode_skpd', this.kode_skpd)
      formData.append('nama_skpd', this.nama_skpd)
      formData.append('kabupaten_kota', this.kabupaten_kota)
      formData.append('kode_lokasi', this.kode_lokasi)
      formData.append('nama_instansi', this.nama_instansi)
      formData.append('letak_lokasi', this.letak_lokasi)
      formData.append('kode_barang', this.kode_barang)
      formData.append('nama_barang', this.nama_barang)
      formData.append('no_hak', this.no_hak)
      formData.append('jenis_hak', this.jenis_hak)
      formData.append('tgl_perolehan', this.tgl_perolehan)
      formData.append('nilai_perolehan', this.nilai_perolehan)
      formData.append('luas_tertulis', this.luas_tertulis)
      formData.append('tgl_sertifikat', this.tgl_sertifikat)
      formData.append('kecamatan', this.kecamatan)
      formData.append('kelurahan', this.kelurahan)
      formData.append('dikuasai', this.dikuasai)
      // alert(JSON.stringify(user));
      axios.post(process.env.VUE_APP_BASE_URL+"addasetbersertifikat", formData)
        .then((response) => {
            // console.log(response);
            if(response.status==200){
            // alert(response.data);
              this.$router.push('/asetwilayah');
            }
            else{
              this.myerror = true;
            }            
        })
    }
  }
}
</script>